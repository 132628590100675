import React from "react"
import ImageGallery from 'react-image-gallery';
import LayoutAsset from "../../components/LayoutAsset"
import { Link, graphql, StaticQuery } from "gatsby"
import Helmet from 'react-helmet'
import styled from 'styled-components'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import 'prismjs/themes/prism-tomorrow.css'
import { GlobalStyle } from '../../components/Commons'
import { colors, media } from '../../tokens'
import { Container, Button, Col, Row } from "react-bootstrap"
import SEO from '../../components/SEO'
import '../../utils/FontAwesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useSiteImages from '../../hooks/use-site-images'
import BackgroundImage from 'gatsby-background-image'

export const AssetInfo = {
   title:"POTION BLAST: BATTLE OF WIZARDS",
   description: "Potion Blast: A fascinating couch-multiplayer versus game - unleash magical potions to blast your opponents with a mesmerizing array of spells. Only the craftiest, luckiest, and most skilled wizard will emerge victorious!",
   keywords: "potion blast, potion, blast, potionblast, wizard, mage, versus, battle of wizards",
   link:"/Games/PotionBlast" ,
   boxImage:"Games/PotionBlast/store_capsule_main.png",
   bannerImage:"Games/PotionBlast/_Banner.png",
   titleImage: "Games/PotionBlast/SteamPage/library_logo_transparent.png",
   videosLink:"https://www.youtube.com/embed/9f-WNf5g5Gk",
   discordLink:"https://discord.gg/aNxuHwbUt3",
   SteamLink:"https://store.steampowered.com/app/1965320/Potion_Blast/",
   pressKitLink:"https://drive.google.com/drive/folders/1DGAnDfD-9INbP2i_N2c6LbxfpHXBgWse?usp=sharing",
   images: [
    {
    original: 'https://cdn.cloudflare.steamstatic.com/steam/apps/1965320/extras/Gameplay_TowerofPeak_TornadoAndVolcano.gif?t=1686225053',
    thumbnail: 'https://cdn.cloudflare.steamstatic.com/steam/apps/1965320/extras/Gameplay_TowerofPeak_TornadoAndVolcano.gif?t=1686225053',
    originalAlt : 'Chaotic elemental battles!',
    thumbnailAlt: 'Chaotic elemental battles!'
    },
    {
    original: 'https://cdn.cloudflare.steamstatic.com/steam/apps/1965320/extras/Metorite_GIF.gif',
    thumbnail: 'https://cdn.cloudflare.steamstatic.com/steam/apps/1965320/extras/Metorite_GIF.gif',
    originalAlt : 'Last to survive wins',
    thumbnailAlt: 'Last to survive wins'
    },
    {
    original: 'https://cdn.cloudflare.steamstatic.com/steam/apps/1965320/extras/MixPotions.gif?t=1686225053',
    thumbnail: 'https://cdn.cloudflare.steamstatic.com/steam/apps/1965320/extras/MixPotions.gif?t=1686225053',
    originalAlt : '2-4 local multiplayer for some serious couch fighting',
    thumbnailAlt: '2-4 local multiplayer for some serious couch fighting'
    },
    {
    original: 'https://cdn.cloudflare.steamstatic.com/steam/apps/1965320/extras/DypPotionDrink_GIF.gif',
    thumbnail: 'https://cdn.cloudflare.steamstatic.com/steam/apps/1965320/extras/DypPotionDrink_GIF.gif',
    originalAlt : 'You can even mod the game to import your own mage!',
    thumbnailAlt: 'You can even mod the game to import your own mage!'
    },
    {
    original: 'https://cdn.cloudflare.steamstatic.com/steam/apps/1965320/extras/Variants_Menu_Gif.gif',
    thumbnail: 'https://cdn.cloudflare.steamstatic.com/steam/apps/1965320/extras/Variants_Menu_Gif.gif',
    originalAlt : 'Customize how you want to play by changing Variants with Millions of combinations',
    thumbnailAlt: 'Customize how you want to play by changing Variants with Millions of combinations'
    }
    
    ],
   
}

const HeaderLogo = styled.div`
    img{
        max-width: 70%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    
    padding:  5em 0 2em 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
`


const Video = styled.div`
    position:relative;
    padding: 0px;
    width: 100%;

    max-width: 1200px;
    margin: 0px auto;

    iframe{
        box-shadow: 0px 0px 64px #000;
        margin: auto;
        display:block;
    }    
      
    div {
        position: relative;
        padding-bottom: 56.25%;
        height: 0px;
    }
    
    iframe {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }
`

const TextContainer = styled.div`
    padding: 3em 0 0 0;
    text-align: center;
    text-size-adjust: auto;
    font-size: 1.1em;
    
`

const PotionBlastContainer = styled(Container)`
    margin: auto;

    .image-gallery{
        width: 60%;
        margin: auto;
    }

    .image-gallery-slides{
        padding-top:56.25%; #Image aspect ratio
        position:relative;
    }

    .image-gallery-slide{
        position:absolute;
        left:0;
        top:0;
        right:0;
        bottom:0;
    }

    .image-gallery-slide > div{
        width:100%;
        height:100%;
    }

    .image-gallery-image{
        object-fit: cover;
        width:100%;
        height:100%;
    }
   
    .image-gallery-thumbnail{
        
    }

    .image-gallery-thumbnail-inner{
        
    }

    .image-gallery-thumbnail-image{
        object-fit: cover;
        width:100px;
        height:56px;
    }
    
`

const ButtonRow = styled(Row)`
    justify-content: space-around;
    padding: 2em 0;

    .btn {
        background-color: ${colors.dypPurple};
        border: none;
        padding: 16px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        transition-duration: 0.4s;
        cursor: pointer;

        text-align:center;
        color: white;
        width: 300px;
        font-size: 25px;

        border-radius: 25px;
    }
    
    .btn {
        background-color: transparent; 
        border: 2px solid ${colors.dypBlueLight};
        color: white;
    }
    
    .btn:hover {
        background-color: ${colors.dypBlueLight};
        color: black;
    }

    .steamBtn {

    }

    .discordBtn{

    }

    .presskitBtn{

    }
`

const ButtonLink = (props)=>{
    const {children, link, icon, additonalClass } = props;
    return(
        <a href={link} className={"button btn "+additonalClass}><FontAwesomeIcon className="fa" icon={icon} /> {children}</a>
    )
}


const Background = ({ className, children }) => {
  
    return (
  
    <StaticQuery
      query={graphql`
        query {
          desktop: file(relativePath: { eq: "Backgrounds/PotionBlastBackground_Desaturated.png" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        // Set ImageData.
        const imageData = data.desktop.childImageSharp.fluid
        return (
          <BackgroundImage
            //Tag="body"
            className={className}
            fluid={imageData}
            //backgroundColor={`#040e18`}
            style={{
              // Defaults are overwrite-able by setting one or each of the following:
              backgroundSize: '',
              backgroundPosition: '',
              backgroundRepeat: '',
            }}
          >
            {children}
          </BackgroundImage>
        )
      }}
    />
  )
}


export default () => {
    
    const { 
        link,
        title,
        description,
        keywords,
        bannerImage,
        boxImage,
        images,
        titleImage,
        videosLink,
        primaryButton,
        demos,
        documentationLink,
        forumLink,
        discordLink,
        SteamLink,
        pressKitLink
        } = AssetInfo

        const imagesConverted = []
            images.map((image)=>{
                var imageCopy = JSON.parse(JSON.stringify(image));
                
                if(imageCopy.original.endsWith(".mp4") == false && imageCopy.original.startsWith('http') == false){
                    if(imageCopy != null){
                        var siteImage = useSiteImages(image.original);
                        if(siteImage != null){
                            imageCopy.original = siteImage.fluid.src;
                        }
                    
                    }
                    
                }
                if(imageCopy.thumbnail.startsWith('http') == false){
                    if(imageCopy != null){
                        var siteImage = useSiteImages(image.thumbnail);
                        if(siteImage != null){
                            imageCopy.thumbnail = siteImage.fluid.src;
                        }
                    }
                }
                imagesConverted.push(imageCopy);
            })

        const titleImageSrc = titleImage
            ? useSiteImages(titleImage).fluid
            : null

        const boxImageSrc = boxImage
            ? useSiteImages(boxImage).fluid
            : null
    
    return (
    <div>
        <SEO
          title={title}
          description={description}
          cover={boxImageSrc.src}
          keywords={keywords}
          path={link}
        />
        <GlobalStyle />
        <Background>
            <PotionBlastContainer>

            <HeaderLogo>
                <img src={titleImageSrc.src} alt="Potion Blast - Battle of Wizards logo"></img>
            </HeaderLogo>
            
            <ButtonRow>
                {/*<SteamButton href= {SteamLink}></SteamButton>*/}
                <ButtonLink additonalClass="steamBtn" link={SteamLink} icon={['fab', 'steam']}>Steam</ButtonLink>
            </ButtonRow>
            
            <Video>
                <div>
                    <iframe src="https://www.youtube.com/embed/Cec0baiUsvc" title="Potion Blast: Battle of Wizards - Release Trailer" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </Video>

            <TextContainer>
                <b>Potion Blast: Battle of Wizards</b> is a fascinating couch-multiplayer versus game where you embody a wizard mixing, sipping and unleashing magical potions to blast your opponents with a mesmerizing array of spells. 
                Only the craftiest, luckiest, and most skilled wizard will emerge victorious!

                <br/>
                <br/>
                Quickly fire single mana spells, or mix potions to make stronger spells.
                <br/>
                Use spells to damage your opponent directly, or make traps all over the stage.
                <br/>
                Keep your distance to stay safe, or get close for some melee action.
                <br/>
                It's up to you to choose the strategy that will lead you to victory!

                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                Join the discord to test your skills against other players!

  
            </TextContainer>
            
            <ButtonRow>
                {/*<DiscordButton href= {discordLink}></DiscordButton>*/}
                <ButtonLink additonalClass="discordBtn" link={discordLink} icon={['fab', 'discord']}>Discord</ButtonLink>
            </ButtonRow>
            

            {/* https://github.com/xiaolin/react-image-gallery */}
            <ImageGallery items={imagesConverted}/>
       
            <ButtonRow>
                {/*<PressKitButton href= {pressKitLink}></PressKitButton>*/}
                <ButtonLink additonalClass="presskitBtn" link={pressKitLink} icon={['fas', 'book']}>PressKit</ButtonLink>
            </ButtonRow>
    

            </PotionBlastContainer>
        </Background>
        <Footer />
    </div>
)
}