import React from "react"
import {LayoutPage} from "../components/Layouts"
import { Link } from "gatsby"
import styled from 'styled-components'
import 'prismjs/themes/prism-tomorrow.css'
import { GlobalStyle } from '../components/Commons'
import LayoutGames from "../components/LayoutGames"
import { AssetInfo as PotionBlastAssetInfo } from "./Games/PotionBlast"
import AssetBox from "../components/AssetBox"
import { Container, Button, Col, Row, Dropdown } from "react-bootstrap"
import { media } from '../tokens'

const Section = styled.section`
  margin: -80px 0px;
  padding-top: 120px
`

const Body = styled(Col)`
  padding-bottom: 30px;
`

export default () => (
    <LayoutGames 
        location="/Games/"
        title="GAMES"
        description="Games"
        >

        <Body md={12}>
            <Section id="Latest">
                <Row>
                    <AssetBox assetInfo={PotionBlastAssetInfo} />
                </Row>
            </Section>
        </Body>
    </LayoutGames>
)