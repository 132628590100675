import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'
import Animation from './Animation'
import SEO from './SEO'
import BigCloud from "../../content/animations/GrosNuage.json";
import 'prismjs/themes/prism-tomorrow.css'
import { GlobalStyle } from './Commons'
import LayoutGeneral from "./Layouts"
import { media } from '../tokens'
import Hero from "./Hero"
import { Container, Button, Col, Row } from "react-bootstrap"

const SiteContent = styled.div`
   
    @media ${media.maxMedium} {
        padding: 80px 0px;
      }
`

const CloudContent = styled.div`
    height: 300px;
    position:relative;
    padding-top: 250px;
    max-width: 600px;
    margin: 0 auto;
`

const CloudAnimationParent = styled.div`
    .animation-container{
        position: absolute;
        width: 600px;
        height: 240px;
        bottom: -50px;
        right:25%;
        left:50%;
        margin-left:-300px;
        margin-top: 10px;
    }
`

const CloudText = styled.h1`
    text-align: center;
    font-size: 1.5em;
    z-index: 88;
    color:black;
    position:relative;
    //font-family: 'Verdana, Geneva, sans-serif';
    font-weight: bold;
`


export default (props) => {
    const { children, location, title, description, noCover, heroImg } = props
    return (
        <LayoutGeneral location={location} noCover={noCover}>
            <SEO
            title={title}
            description={description}
            path={location}
            />

            <SiteContent>

                <Hero
                heroImg={heroImg}
                title={title}
                />

                <Container>{children}</Container>

                <CloudAnimationParent>
                    <Animation animation={BigCloud}></Animation>
                </CloudAnimationParent>

                <CloudContent>
                <CloudText>
                    Developing
                </CloudText>
                <CloudText>
                    Our Dreams...
                </CloudText>
                </CloudContent>
        
            </SiteContent>
            
        </LayoutGeneral>
    )
}